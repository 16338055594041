import { v4 } from 'uuid';
import { voices } from '../data/voices/voices';
import { data as backgroundsLibrary } from '../data/backgroundsLibrary';
import { data as songsList } from '../data/dataForMusic';
import { videoTemplate, sceneTemplate } from '../data/templates/basic';
import {
  validateFulfilled,
  getMedias,
  getVoiceOvers,
  updateVideosWithError,
  buildVideoSection,
  getVideosBySections,
  getErrorMessages
} from '../helpers/helper';
import * as Sentry from '@sentry/nextjs';

import { sendPrompt } from '../helpers/gpt';

import { dreaming } from '../helpers/stability.services';
import { getCredits, updateCredits } from '../helpers/credits.services';
import cloneDeep from 'lodash/cloneDeep';
import Toast from '~/helpers/notification';

export const useCreateStore = (set, get) => ({
  // state
  videoProportion: 0.2,
  numberVideosSection: 1,

  // actions
  createAction: (prompt) => {
    get().trackingAction({
      event: 'onButtonClick',
      category: 'idea-to-video',
      action: 'create',
      label: `${prompt}`
    });

    if (!get().user) {
      get().redirectSignUpAction();
    } else {
      if (get().credits > 0) {
        const _creditsCost =
          get().creationCosts * (get().selectedCategories.length * get().numberVideosSection);

        let _sections = [];
        get().selectedCategories.forEach((s) => {
          get().categories.forEach((c) => {
            if (s === c().label) {
              let _videos = [{ ...videoTemplate() }];

              _sections.push({
                id: v4(),
                text: c(prompt).topic,
                videos: _videos,
                category: c().label,
                isLoading: true,
                errors: [],
                medias: [],
                voiceover: [],
                creation: { time: '00:00', step: 0 },
                prompts: c(prompt, get().language.label).promptContent
              });
            }
          });
        });

        set({
          prompt: prompt,
          creditsCost: _creditsCost,
          sections: [..._sections],
          videosBySections: getVideosBySections(_sections)
        });

        get().createSectionsAction();
      } else {
        get().setShowCreditsAction(true);
      }
    }
  },

  createSectionsAction: async () => {
    // !- INFO: validate credits
    try {
      get().startCreationTimeAction();
      set({ isLoading: true, errorMessage: getErrorMessages() }, false, 'Create Sections');

      const creationId = v4();
      const creationDate = new Date();

      const creditsRemaining = await getCredits(get().user._id, get().plan);

      if (get().creditsCost <= creditsRemaining) {
        let _sections = [...get().sections];
        // console.log('_sections', _sections);

        Promise.allSettled(
          _sections.map((section, sectionIdx) => {
            // --- Update creation steps
            (async () => {
              // Step - 2
              await new Promise((resolve) => setTimeout(resolve, 12000));
              if (_sections[sectionIdx].creation.step < 1) {
                _sections[sectionIdx].creation.step = 1;
                set({ sections: [..._sections] }, false, 'Update creation step - 2');
              }
            })();

            return new Promise((resolveSection, rejectSection) => {
              if (section.isLoading) {
                (async () => {
                  try {
                    // Send prompts
                    const sectionVideos = await sendPrompt(section.prompts, get().systemRolPrompt);
                    // console.log('sectionVideos_', section, sectionIdx, sectionVideos);

                    if (sectionVideos.length > 4) {
                      sectionVideos.length = 4;
                    } // videos per section

                    let _videosSection = [];
                    sectionVideos.forEach((v, idx) => {
                      if (!v.meta.mediaDescription) throw 'mediaDescription are missing';

                      let _video = videoTemplate();
                      _video = {
                        ..._video,
                        sectionId: creationId,
                        videoId: v4(),
                        userId: get().user._id,
                        publishedDate: creationDate,
                        meta: {
                          ...v.meta,
                          category: section.category,
                          prompt: get().prompt
                        },
                        isCreated: true
                      };
                      v.scenes?.forEach((s, i) => {
                        _video.clips[i] = sceneTemplate();
                      });

                      _videosSection[idx] = _video;
                    });

                    _sections[sectionIdx].videos = [..._videosSection];
                    set(
                      {
                        sections: [..._sections],
                        videosBySections: getVideosBySections(_sections)
                      },
                      false,
                      'Update videos in section'
                    );

                    // --- Captions
                    sectionVideos.map((v) => {
                      v.scenes.map((s) => {
                        s.captions = s.voiceover
                          .replace(/[~]/g, '')
                          .replace(/\s+/g, ' ')
                          .replace(/ /g, ' ~ ');
                        s.voiceover = s.voiceover.replace(/[~*]/g, '').replace(/\s+/g, ' ');
                      });
                    });
                    // console.log('sectionVideos_', sectionVideos);

                    // --- Update creation steps
                    (async () => {
                      // Step - 3
                      await new Promise((resolve) => setTimeout(resolve, 1000));
                      if (_sections[sectionIdx].creation.step < 2) {
                        _sections[sectionIdx].creation.step = 2;
                        set({ sections: [..._sections] }, false, 'Update creation step - 3');
                      }

                      // Step - 4
                      await new Promise((resolve) => setTimeout(resolve, 7000));
                      if (_sections[sectionIdx].creation.step < 3) {
                        _sections[sectionIdx].creation.step = 3;
                        set({ sections: [..._sections] }, false, 'Update creation step - 4');
                      }
                    })();

                    // Get medias
                    const medias = new Promise((resolve, reject) => {
                      (async () => {
                        let medias = [];
                        if (get().background.value.indexOf('ai-art') !== -1) {
                          const _medias = await dreaming(
                            sectionVideos,
                            get().background.prompts,
                            get().background.style_preset
                          );

                          const fulfilled = validateFulfilled(_medias);
                          if (fulfilled) {
                            _medias.forEach((m) => {
                              medias.push(m.value);
                            });
                          } else {
                            reject('An error');
                          }
                        } else {
                          let _medias = getMedias(backgroundsLibrary, [get().background.value]);
                          _medias = _medias.sort(() => 0.5 - Math.random());
                          let mIdx = 0;

                          sectionVideos.forEach((v) => {
                            let _med = [];
                            v.scenes?.forEach(() => {
                              _med.push(_medias[mIdx]);
                              if (mIdx < _medias.length - 1) mIdx = mIdx + 1;
                              else mIdx = 0;
                            });
                            medias.push(_med);
                          });
                        }

                        resolve(medias);
                      })();
                    });

                    // Get voiceover
                    const voiceover = new Promise((resolve, reject) => {
                      (async () => {
                        let voiceOvers = [];
                        const _voiceOvers = await getVoiceOvers(
                          sectionVideos,
                          get().voiceOver,
                          voices,
                          get().preset.value
                        );
                        const fulfilled = validateFulfilled(_voiceOvers);
                        if (fulfilled) {
                          _voiceOvers.forEach((m) => {
                            voiceOvers.push(m.value);
                          });
                        } else {
                          reject('An error');
                        }

                        resolve(voiceOvers);
                      })();
                    });

                    // Get music
                    const music = new Promise((resolve) => {
                      let music = [];
                      if (get().music.value !== 'none') {
                        if (get().music.value === 'AI') {
                          music = cloneDeep(
                            songsList[sectionVideos[0].musicCategory.toLowerCase()]
                          ).sort(() => 0.5 - Math.random());
                          music.length = _sections[sectionIdx].videos.length;
                        } else {
                          music = cloneDeep(songsList[get().music.value]).sort(
                            () => 0.5 - Math.random()
                          );
                          music.length = _sections[sectionIdx].videos.length;
                        }
                      }

                      resolve(music);
                    });

                    Promise.allSettled([medias, voiceover, music]).then((values) => {
                      // console.log('V__', values, sectionIdx);

                      // Step - 5
                      _sections[sectionIdx].creation.step = 4;
                      set({ sections: [..._sections] }, false, 'Update creation step - 5');

                      const fulfilled = validateFulfilled(values);
                      if (fulfilled) {
                        let _currentSection = _sections[sectionIdx];
                        const _medias = values[0].value;
                        const _voiceoverAudio = {
                          disabled: get().voiceOver.disabled,
                          audios: values[1].value
                        };
                        const _music = values[2].value;

                        let _voiceover = [];
                        sectionVideos.forEach((s) => {
                          _voiceover.push({ scenes: s.scenes });
                        });

                        let _section = buildVideoSection(
                          _currentSection,
                          _voiceover,
                          _medias,
                          _voiceoverAudio,
                          _music,
                          get().videoProportion,
                          get().preset.value,
                          get().textStyles
                        );

                        // --- Update metadata
                        const settings = get();
                        const {
                          background,
                          music,
                          voiceOver,
                          font,
                          type,
                          color,
                          preset,
                          textStyles,
                          language
                        } = settings;

                        _section.videos.forEach((v, i) => {
                          v.meta = {
                            ...v.meta,
                            medias: _medias[i],
                            voiceover: _voiceover[i],
                            voiceoverAudio: _voiceoverAudio.audios[i],
                            music: _music[i],
                            settings: {
                              background,
                              music,
                              voiceOver,
                              text: {
                                font,
                                type,
                                color,
                                preset,
                                textStyles
                              },
                              language
                            }
                          };
                        });

                        (async () => {
                          // !- INFO: discount the credits
                          const data = {
                            userId: get().user._id,
                            usage: _section.videos.length * get().creationCosts,
                            plan: get().plan,
                            action: 'video-creation'
                          };
                          const creditsRemaining = await updateCredits(data);

                          if (creditsRemaining >= 0) {
                            _sections[sectionIdx] = _section;
                            set(
                              {
                                sections: [..._sections],
                                videosBySections: getVideosBySections(_sections),
                                credits: creditsRemaining
                              },
                              false,
                              'Built section'
                            );

                            // !- Save videos in history
                            await get().saveVideosHistoryAction(_section);

                            resolveSection();
                          } else {
                            _sections[sectionIdx].errors.push({
                              error: 'Error discounting credits'
                            });
                            _sections[sectionIdx].videos = updateVideosWithError(
                              [..._sections[sectionIdx].videos],
                              get().videoProportion
                            );
                            _sections[sectionIdx].isLoading = false;
                            set(
                              {
                                sections: [..._sections],
                                videosBySections: getVideosBySections(_sections)
                              },
                              false,
                              'Error discounting credits'
                            );

                            rejectSection();
                          }
                        })();
                      } else {
                        // ! Error
                        _sections[sectionIdx].errors.push({ error: 'An error' }); // TODO: Specify the error
                        _sections[sectionIdx].videos = updateVideosWithError(
                          [..._sections[sectionIdx].videos],
                          get().videoProportion
                        );
                        _sections[sectionIdx].isLoading = false;
                        set(
                          {
                            sections: [..._sections],
                            videosBySections: getVideosBySections(_sections)
                          },
                          false,
                          'Error in section'
                        );
                        get().trackingAction({
                          event: 'onButtonClick',
                          category: 'idea-to-video',
                          action: 'create-error',
                          label: `${get().prompt}`,
                          params: {}
                        });
                        Sentry.withScope((scope) => {
                          Sentry.captureMessage('Failed to generate video');
                        });
                        rejectSection();
                      }
                    });
                  } catch (err) {
                    console.log('ERROR!_', err);
                    _sections[sectionIdx].errors.push({ error: err });
                    _sections[sectionIdx].videos = updateVideosWithError(
                      [..._sections[sectionIdx].videos],
                      get().videoProportion
                    );
                    _sections[sectionIdx].isLoading = false;
                    set(
                      {
                        sections: [..._sections],
                        videosBySections: getVideosBySections(_sections)
                      },
                      false,
                      'Error in section'
                    );
                    get().trackingAction({
                      event: 'onButtonClick',
                      category: 'idea-to-video',
                      action: 'create-error',
                      label: `${get().prompt}`
                    });
                    Sentry.withScope((scope) => {
                      Sentry.captureMessage('Failed to generate video');
                    });
                    rejectSection(err);
                  }
                })();
              } else {
                resolveSection();
              }
            });
          })
        ).then(() => {
          set({ isLoading: false }, false, 'Create end');
          get().stopCreationTimeAction();
          get().trackingAction({
            event: 'onButtonClick',
            category: 'idea-to-video',
            action: 'create-end',
            label: `${get().prompt}`
          });

          // toast for credits
          let _credits = 0;
          get().videosBySections.forEach((v) => {
            if (!v.video.error) _credits = _credits + get().creationCosts;
          });
          if (_credits > 0) {
            Toast.success(`Discount applied! ${_credits} credits deducted for video creation.`);
          }

          get().setPromptsHistoryAction(get().prompt);

          get().setVideoEditSettingAction();
        });
      } else {
        get().stopCreationTimeAction();

        get().setShowCreditsAction(true);

        let _sections = [...get().sections];
        _sections.map((section, sectionIdx) => {
          if (section.isLoading) {
            _sections[sectionIdx].errors.push({ error: 'Error credits' });
            _sections[sectionIdx].videos = updateVideosWithError(
              [..._sections[sectionIdx].videos],
              get().videoProportion
            );
            _sections[sectionIdx].isLoading = false;
            set(
              {
                sections: [..._sections],
                videosBySections: getVideosBySections(_sections),
                isLoading: false
              },
              false,
              'Error discounting credits'
            );
          }
        });
      }
    } catch (error) {
      console.error('ERROR - CREDITS', error);

      let _sections = [...get().sections];
      _sections.map((section, sectionIdx) => {
        _sections[sectionIdx].errors.push({ error: 'Error credits' });
        _sections[sectionIdx].videos = updateVideosWithError(
          [..._sections[sectionIdx].videos],
          get().videoProportion
        );
        _sections[sectionIdx].isLoading = false;
        set(
          {
            sections: [..._sections],
            videosBySections: getVideosBySections(_sections),
            isLoading: false
          },
          false,
          'Error discounting credits'
        );
      });

      throw new Error('Error in getting the credits');
    }
  }
});
