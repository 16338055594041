import { v4 } from 'uuid';
import {
  getMedias,
  validateFulfilled,
  updateVideosWithError,
  getVideosBySections,
  getBackgroundMessages,
  getErrorMessages,
  buildVideoSection
} from '../helpers/helper';

import { data as backgroundsLibrary } from '../data/backgroundsLibrary';

import { dreaming } from '../helpers/stability.services';
import { getCredits, updateCredits } from '../helpers/credits.services';
import Toast from '~/helpers/notification';

export const useBackgroundStore = (set, get) => ({
  // state
  background: {
    value: 'ai-art_digital-art',
    label: 'Digital Art',
    prompts: [],
    style_preset: 'digital-art'
  },
  backgroundMessage: '',
  showBackground: false,

  // actions
  setBackgroundAction: (bg) => {
    const setBackground = () => {
      const background = bg.value;

      if (background !== get().background.value) {
        (async () => {
          let _sections = [...get().sections];
          let validatedCredits = false;

          set(
            {
              isLoading: true,
              changeRunning: 'background',
              backgroundMessage: getBackgroundMessages(),
              errorMessage: getErrorMessages()
            },
            false,
            'Set background loading'
          );

          _sections.map((section, sectionIdx) => {
            if (section.errors?.length === 0) {
              _sections[sectionIdx].isLoading = true;
              set(
                {
                  sections: [..._sections],
                  videosBySections: getVideosBySections(_sections)
                },
                false,
                'Loading section - bg'
              );
            }
          });

          if (background.indexOf('ai-art') !== -1) {
            if (get().credits > 0) {
              // !- INFO: validate credits
              try {
                const creditsRemaining = await getCredits(get().user._id, get().plan);

                const videos = getVideosBySections(get().sections);
                const creditsCost = videos.length * get().editionCosts;
                if (creditsCost <= creditsRemaining) {
                  validatedCredits = true;
                } else {
                  get().setShowCreditsAction(true);
                }
              } catch (error) {
                console.error('ERROR - CREDITS', error);
                throw new Error('Error in getting the credits');
              }
            } else {
              get().setShowCreditsAction(true);
            }
          } else {
            validatedCredits = true;
          }

          if (validatedCredits) {
            set(
              {
                videoEditSetting: {
                  ...get().videoEditSetting,
                  background: bg
                }
              },
              false,
              'Set background'
            );

            Promise.allSettled(
              _sections.map((section, sectionIdx) => {
                return new Promise((resolve, reject) => {
                  if (section.errors?.length === 0) {
                    (async () => {
                      try {
                        _sections[sectionIdx].id = v4();
                        set(
                          {
                            sections: [..._sections],
                            videosBySections: getVideosBySections(_sections)
                          },
                          false,
                          'Loading section - background'
                        );

                        let medias = [];
                        if (get().background.value.indexOf('ai-art') !== -1) {
                          const _medias = await dreaming(
                            section.videos,
                            bg.prompts,
                            bg.style_preset
                          );

                          const fulfilled = validateFulfilled(_medias);
                          if (fulfilled) {
                            _medias.forEach((m) => {
                              medias.push(m.value);
                            });
                          } else {
                            _sections[sectionIdx].errors.push({ error: 'An error' });
                            _sections[sectionIdx].videos = updateVideosWithError(
                              [..._sections[sectionIdx].videos],
                              get().videoProportion
                            );
                            _sections[sectionIdx].isLoading = false;
                            set(
                              {
                                sections: [..._sections],
                                videosBySections: getVideosBySections(_sections)
                              },
                              false,
                              'Error in background'
                            );

                            reject('An error');
                          }
                        } else {
                          let _medias = getMedias(backgroundsLibrary, [get().background.value]);
                          _medias = _medias.sort(() => 0.5 - Math.random());
                          let mIdx = 0;

                          section.videos.forEach(() => {
                            medias.push([_medias[mIdx], _medias[mIdx + 1]]);
                            if (mIdx + 2 > _medias.length - 1) mIdx = 0;
                            else mIdx = 2;
                          });
                        }

                        let _currentSection = section;
                        const _medias = medias;
                        const _voiceoverAudio = section.voiceoverAudio;
                        const _music = section.music;
                        let _voiceover = section.voiceover;

                        const _section = buildVideoSection(
                          _currentSection,
                          _voiceover,
                          _medias,
                          _voiceoverAudio,
                          _music,
                          get().videoProportion,
                          get().preset.value,
                          get().textStyles
                        );

                        let discountedCredits = false;
                        if (get().background.value.indexOf('ai-art') !== -1) {
                          // !- INFO: discount the credits
                          const data = {
                            userId: get().user._id,
                            usage: _section.videos.length * get().editionCosts,
                            plan: get().plan,
                            action: 'change-background'
                          };
                          const creditsRemaining = await updateCredits(data);

                          if (creditsRemaining >= 0) {
                            discountedCredits = true;
                            set({ credits: creditsRemaining });
                          }
                        } else {
                          discountedCredits = true;
                        }

                        if (discountedCredits) {
                          _sections[sectionIdx] = _section;

                          set(
                            {
                              sections: [..._sections],
                              videosBySections: getVideosBySections(_sections)
                            },
                            false,
                            'Update section - background'
                          );

                          // !- Update videos in history
                          if (_section.videos[0]?.isCreated) {
                            await get().updateVideosHistoryAction(get().user._id, _section);
                          }

                          resolve(medias);
                        } else {
                          console.log('ERROR!');
                          _sections[sectionIdx].errors.push({ error: 'no credits' });
                          _sections[sectionIdx].videos = updateVideosWithError(
                            [..._sections[sectionIdx].videos],
                            get().videoProportion
                          );
                          _sections[sectionIdx].isLoading = false;
                          set(
                            {
                              sections: [..._sections],
                              videosBySections: getVideosBySections(_sections)
                            },
                            false,
                            'Error in section'
                          );

                          reject('An error');
                        }
                      } catch (err) {
                        console.log('ERROR!_', err);
                        _sections[sectionIdx].errors.push({ error: err });
                        _sections[sectionIdx].videos = updateVideosWithError(
                          [..._sections[sectionIdx].videos],
                          get().videoProportion
                        );
                        _sections[sectionIdx].isLoading = false;
                        set(
                          {
                            sections: [..._sections],
                            videosBySections: getVideosBySections(_sections)
                          },
                          false,
                          'Error in section'
                        );

                        reject(err);
                      }
                    })();
                  } else {
                    resolve();
                  }
                });
              })
            ).then((values) => {
              set({ isLoading: false, changeRunning: 'none' });

              // toast for credits
              if (get().background.value.indexOf('ai-art') !== -1) {
                let _credits = 0;
                get().videosBySections.forEach((v) => {
                  if (!v.video.error) _credits = _credits + get().editionCosts;
                });
                if (_credits > 0) {
                  Toast.success(
                    `Discount applied! ${_credits} credits deducted for video editing.`
                  );
                }
              }
            });

            get().trackingAction({
              event: 'onButtonClick',
              category: 'idea-to-video',
              action: 'change-background',
              label: `${bg.value}`
            });
          } else {
            _sections.map((section, sectionIdx) => {
              if (section.errors?.length === 0) {
                _sections[sectionIdx].isLoading = false;
                set(
                  {
                    sections: [..._sections],
                    videosBySections: getVideosBySections(_sections)
                  },
                  false,
                  'Set section - bg'
                );
              }
            });

            set({ isLoading: false, changeRunning: 'none' });
          }
        })();
      }
    };

    if (!get().user) {
      get().redirectSignUpAction();
    } else {
      if (bg.tier === 'premium') {
        if (!get().plan) {
          set({
            premiumModal: { type: 'background', show: true }
          });
        } else {
          setBackground();
        }

        get().trackingAction({
          event: 'onButtonClick',
          category: 'idea-to-video',
          action: 'premium-background',
          label: `${bg.value}`
        });
      } else {
        setBackground();
      }
    }
  },

  changeDefaultBackgroundAction: () => {
    const _background = {
      value: 'ai-art_digital-art',
      label: 'Digital Art',
      prompts: [],
      style_preset: 'digital-art'
    };
    get().setBackgroundAction(_background);
    set({ openCustomizationOption: 'bg' });
  }
});
