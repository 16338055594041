import { getSettings, updateSettings } from '../helpers/settings.services';

export const useSettingStore = (set, get) => ({
  // state
  videoEditSetting: {
    background: null,
    music: null,
    voiceOver: null,
    text: null
  },
  showSettings: false,
  isLoadingSettings: true,

  // actions
  setVideoEditSettingAction: () => {
    const videoEditSetting = {
      background: get().background,
      music: get().music,
      voiceOver: get().voiceOver,
      text: {
        font: get().font,
        type: get().type,
        color: get().color,
        preset: get().preset,
        textStyles: get().textStyles
      }
    };
    set({ videoEditSetting });
  },

  setShowSettingsAction: (showSettings) => {
    set({ showSettings });
    if (showSettings) {
      get().trackingAction({
        event: 'onButtonClick',
        category: 'idea-to-video',
        action: 'settings'
      });

      setTimeout(() => {
        let body = document.querySelector('body');
        if (body) {
          body.style.overflowY = 'hidden';
        }
      });
    } else {
      let body = document.querySelector('body');
      if (body) {
        body.style.overflowY = 'scroll';
      }
    }
  },

  applySettingsAction: (settings) => {
    const { categories, background, music, voiceOver, text, language } = settings;
    set({
      selectedCategories: categories,
      background,
      music,
      voiceOver: voiceOver.voiceOver,
      voiceOptions: voiceOver.voiceOptions,
      font: text.selectedFont,
      type: text.selectedType,
      color: text.selectedColor,
      preset: text.selectedPreset,
      textStyles: text.selectedTextStyles,
      language
    });
    get().setShowSettingsAction(false);

    const _settings = {
      background: background,
      categories: categories,
      language: language,
      music: music,
      text: {
        font: text.selectedFont,
        type: text.selectedType,
        color: text.selectedColor,
        preset: text.selectedPreset,
        textStyles: text.selectedTextStyles
      },
      voiceOver: voiceOver.voiceOver
    };

    get().updateSettingsAction(get().user._id, _settings);
  },

  getSettingsAction: async () => {
    try {
      set({ isLoadingSettings: true });
      const _settings = await getSettings(get().user._id);

      if (_settings._id) {
        const { background, categories, language, music, text, voiceOver } = _settings;
        set({
          background,
          selectedCategories: categories,
          language,
          music,
          font: text.font,
          type: text.type,
          color: text.color,
          preset: text.preset,
          textStyles: text.textStyles,
          voiceOver
        });
        get().changeVoiceOptionsAction(language);
        get().setVideoEditSettingAction();
      }

      set({ isLoadingSettings: false });
    } catch (error) {
      console.error(error);
      set({ isLoadingSettings: false });
    }
  },

  updateSettingsAction: (userId, settings) => {
    try {
      updateSettings(userId, settings);
    } catch (error) {
      console.error(error);
    }
  }
});
