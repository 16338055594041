export const data: any = [
  {
    value: 'EN',
    label: 'English',
    flag: 'US'
  },
  {
    value: 'ES',
    label: 'Spanish',
    flag: 'ES'
  },
  {
    value: 'FR',
    label: 'French',
    flag: 'FR'
  },
  {
    value: 'HI',
    label: 'Hindi',
    flag: 'IN'
  },
  {
    value: 'DE',
    label: 'German',
    flag: 'DE'
  },
  {
    value: 'PT',
    label: 'Portuguese',
    flag: 'PT'
  }
];
