import chroma from 'chroma-js';
import {
  buildVideoSection,
  getErrorMessages,
  getVideosBySections,
  getVoiceOverMessages,
  getVoiceOvers,
  updateVideosWithError,
  validateFulfilled
} from '../helpers/helper';

import { voices } from '../data/voices/voices';

export const useTextStylesStore = (set, get) => ({
  // state
  font: 'boldify',
  type: 'stroke', // background | background-opacity | stroke | basic
  color: '#FFFFFF',
  preset: { label: 'Word by word', value: 'word_by_word' }, // typewriter | phrases_and_words | word_by_word
  textStyles: {
    top: 'bottom', // center | top | bottom
    textAlign: 'center', // center | left | right,
    fill: '#FFFFFF',
    backgroundColor: '#000000',
    customFont: {
      fontFamily: 'Montserrat-ExtraBold',
      path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf'
    },
    fontSize: '60',
    fontWeight: 600,
    stroke: '#000000',
    strokeWidth: 8,
    shadow: null,
    type: 'woxo-custom-text-basic',
    hide: false
  },

  // actions
  setFontAction: (t) => {
    set({
      videoEditSetting: {
        ...get().videoEditSetting,
        text: {
          ...get().videoEditSetting.text,
          font: t.value,
          textStyles: {
            ...get().videoEditSetting.text.textStyles,
            customFont: {
              fontFamily: t.fontFamily,
              path: t.path
            }
          }
        }
      }
    });

    get().updateTypesAndColors();
    get().setTextStyleAction();
  },

  setTypeAction: () => {
    if (get().videoEditSetting.text.font !== 'neon') {
      switch (get().videoEditSetting.text.type) {
        case 'basic':
          set({
            videoEditSetting: {
              ...get().videoEditSetting,
              text: {
                ...get().videoEditSetting.text,
                type: 'stroke',
                textStyles: {
                  ...get().videoEditSetting.text.textStyles,
                  type: 'woxo-custom-text-basic'
                }
              }
            }
          });
          break;
        case 'stroke':
          set({
            videoEditSetting: {
              ...get().videoEditSetting,
              text: {
                ...get().videoEditSetting.text,
                type: 'background',
                textStyles: {
                  ...get().videoEditSetting.text.textStyles,
                  type: 'woxo-custom-text-step-up'
                }
              }
            }
          });
          break;
        case 'background':
          set({
            videoEditSetting: {
              ...get().videoEditSetting,
              text: {
                ...get().videoEditSetting.text,
                type: 'background-opacity',
                textStyles: {
                  ...get().videoEditSetting.text.textStyles,
                  type: 'woxo-custom-text-step-up'
                }
              }
            }
          });
          break;
        default:
          set({
            videoEditSetting: {
              ...get().videoEditSetting,
              text: {
                ...get().videoEditSetting.text,
                type: 'basic',
                textStyles: {
                  ...get().videoEditSetting.text.textStyles,
                  type: 'woxo-custom-text-basic'
                }
              }
            }
          });
          break;
      }
      get().updateTypesAndColors();
      get().setTextStyleAction();
    }
  },

  setTextAlignAction: () => {
    switch (get().videoEditSetting.text.textStyles.textAlign) {
      case 'center':
        set({
          videoEditSetting: {
            ...get().videoEditSetting,
            text: {
              ...get().videoEditSetting.text,
              textStyles: {
                ...get().videoEditSetting.text.textStyles,
                textAlign: 'left'
              }
            }
          }
        });
        break;
      case 'left':
        set({
          videoEditSetting: {
            ...get().videoEditSetting,
            text: {
              ...get().videoEditSetting.text,
              textStyles: {
                ...get().videoEditSetting.text.textStyles,
                textAlign: 'right'
              }
            }
          }
        });
        break;
      default:
        set({
          videoEditSetting: {
            ...get().videoEditSetting,
            text: {
              ...get().videoEditSetting.text,
              textStyles: {
                ...get().videoEditSetting.text.textStyles,
                textAlign: 'center'
              }
            }
          }
        });
        break;
    }
    get().setTextStyleAction();
  },

  setVerticalAlignAction: () => {
    switch (get().videoEditSetting.text.textStyles.top) {
      case 'center':
        set({
          videoEditSetting: {
            ...get().videoEditSetting,
            text: {
              ...get().videoEditSetting.text,
              textStyles: {
                ...get().videoEditSetting.text.textStyles,
                top: 'bottom'
              }
            }
          }
        });
        break;
      case 'bottom':
        set({
          videoEditSetting: {
            ...get().videoEditSetting,
            text: {
              ...get().videoEditSetting.text,
              textStyles: {
                ...get().videoEditSetting.text.textStyles,
                top: 'top'
              }
            }
          }
        });
        break;
      default:
        set({
          videoEditSetting: {
            ...get().videoEditSetting,
            text: {
              ...get().videoEditSetting.text,
              textStyles: {
                ...get().videoEditSetting.text.textStyles,
                top: 'center'
              }
            }
          }
        });
        break;
    }
    get().setTextStyleAction();
  },

  setColorAction: (color) => {
    set({
      videoEditSetting: {
        ...get().videoEditSetting,
        text: {
          ...get().videoEditSetting.text,
          color: color
        }
      }
    });
    get().updateTypesAndColors();
    get().setTextStyleAction();
  },

  updateTypesAndColors: () => {
    if (get().videoEditSetting.text.preset.value === 'typewriter') {
      // TYPE TEXT
      if (get().videoEditSetting.text.font === 'neon') {
        set({
          videoEditSetting: {
            ...get().videoEditSetting,
            text: {
              ...get().videoEditSetting.text,
              textStyles: {
                ...get().videoEditSetting.text.textStyles,
                type: 'woxo-custom-text-basic'
              }
            }
          }
        });
      } else {
        switch (get().videoEditSetting.text.type) {
          case 'basic':
          case 'stroke':
            set({
              videoEditSetting: {
                ...get().videoEditSetting,
                text: {
                  ...get().videoEditSetting.text,
                  textStyles: {
                    ...get().videoEditSetting.text.textStyles,
                    type: 'woxo-custom-text-basic'
                  }
                }
              }
            });
            break;
          case 'background':
          case 'background-opacity':
            set({
              videoEditSetting: {
                ...get().videoEditSetting,
                text: {
                  ...get().videoEditSetting.text,
                  textStyles: {
                    ...get().videoEditSetting.text.textStyles,
                    type: 'woxo-custom-text-step-up'
                  }
                }
              }
            });
            break;
        }
      }

      // COLORS
      if (get().videoEditSetting.text.font !== 'neon') {
        switch (get().videoEditSetting.text.type) {
          case 'stroke':
            set({
              videoEditSetting: {
                ...get().videoEditSetting,
                text: {
                  ...get().videoEditSetting.text,
                  textStyles: {
                    ...get().videoEditSetting.text.textStyles,
                    stroke: get().videoEditSetting.text.color === '#FFFFFF' ? '#000000' : '#FFFFFF',
                    strokeWidth: 8,
                    fill: get().videoEditSetting.text.color,
                    backgroundColor: '',
                    shadow: null
                  }
                }
              }
            });
            break;
          case 'background':
            set({
              videoEditSetting: {
                ...get().videoEditSetting,
                text: {
                  ...get().videoEditSetting.text,
                  textStyles: {
                    ...get().videoEditSetting.text.textStyles,
                    backgroundColor:
                      get().videoEditSetting.text.color === '#FFFFFF'
                        ? '#FFFFFF'
                        : get().videoEditSetting.text.color,
                    fill: get().videoEditSetting.text.color === '#FFFFFF' ? '#000000' : '#FFFFFF',
                    shadow: null,
                    stroke: null,
                    strokeWidth: null
                  }
                }
              }
            });
            break;
          case 'background-opacity':
            set({
              videoEditSetting: {
                ...get().videoEditSetting,
                text: {
                  ...get().videoEditSetting.text,
                  textStyles: {
                    ...get().videoEditSetting.text.textStyles,
                    backgroundColor: chroma(get().videoEditSetting.text.color).alpha(0.5),
                    fill: get().videoEditSetting.text.color === '#FFFFFF' ? '#000000' : '#FFFFFF',
                    shadow: null,
                    stroke: null,
                    strokeWidth: null
                  }
                }
              }
            });
            break;
          default:
            set({
              videoEditSetting: {
                ...get().videoEditSetting,
                text: {
                  ...get().videoEditSetting.text,
                  textStyles: {
                    ...get().videoEditSetting.text.textStyles,
                    backgroundColor: '',
                    fill:
                      get().videoEditSetting.text.color === '#FFFFFF'
                        ? '#FFFFFF'
                        : get().videoEditSetting.text.color,
                    shadow: null,
                    stroke: null,
                    strokeWidth: null
                  }
                }
              }
            });
            break;
        }
      } else {
        set({
          videoEditSetting: {
            ...get().videoEditSetting,
            text: {
              ...get().videoEditSetting.text,
              textStyles: {
                ...get().videoEditSetting.text.textStyles,
                fill: '#FFFFFF',
                backgroundColor: '',
                shadow: `${get().videoEditSetting.text.color} 0px 0px 16px`,
                stroke: null,
                strokeWidth: null
              }
            }
          }
        });
      }

      // FONTSIZE
      if (
        get().videoEditSetting.text.font === 'handwriting' ||
        get().videoEditSetting.text.font === 'neon'
      ) {
        set({
          videoEditSetting: {
            ...get().videoEditSetting,
            text: {
              ...get().videoEditSetting.text,
              textStyles: {
                ...get().videoEditSetting.text.textStyles,
                fontSize: 75
              }
            }
          }
        });
      } else {
        set({
          videoEditSetting: {
            ...get().videoEditSetting,
            text: {
              ...get().videoEditSetting.text,
              textStyles: {
                ...get().videoEditSetting.text.textStyles,
                fontSize: 60
              }
            }
          }
        });
      }
    } else {
      set({
        videoEditSetting: {
          ...get().videoEditSetting,
          text: {
            ...get().videoEditSetting.text,
            textStyles: {
              ...get().videoEditSetting.text.textStyles,
              stroke: get().videoEditSetting.text.color === '#FFFFFF' ? '#000000' : '#FFFFFF',
              fill: get().videoEditSetting.text.color
            }
          }
        }
      });
    }
  },

  setTextStyleAction: () => {
    let _sections = [...get().sections];

    _sections.map((section, sectionIdx) => {
      if (section.errors?.length === 0) {
        let _currentSection = section;
        const _medias = section.medias;
        const _voiceoverAudio = section.voiceoverAudio;
        const _music = section.music;
        let _voiceover = section.voiceover;

        const _section = buildVideoSection(
          _currentSection,
          _voiceover,
          _medias,
          _voiceoverAudio,
          _music,
          get().videoProportion,
          get().videoEditSetting.text.preset.value,
          get().videoEditSetting.text.textStyles
        );

        _sections[sectionIdx] = _section;
        set(
          {
            sections: [..._sections],
            videosBySections: getVideosBySections(_sections)
          },
          false,
          'Update section - voice0ver'
        );

        // !- Update videos in history
        if (_section.videos[0]?.isCreated) {
          get().updateVideosHistoryAction(get().user._id, _section);
        }
      }
    });
  },

  setHideTextAction: () => {
    set({
      videoEditSetting: {
        ...get().videoEditSetting,
        text: {
          ...get().videoEditSetting.text,
          textStyles: {
            ...get().videoEditSetting.text.textStyles,
            hide: !get().videoEditSetting.text.textStyles.hide
          }
        }
      }
    });
    get().setTextStyleAction();
  },

  setPresetAction: async (preset) => {
    set({
      videoEditSetting: {
        ...get().videoEditSetting,
        text: {
          ...get().videoEditSetting.text,
          preset: preset
        }
      },
      isLoading: true,
      changeRunning: 'voiceover',
      voiceoverMessage: getVoiceOverMessages(),
      errorMessage: getErrorMessages()
    });

    if (preset.value !== 'typewriter') {
      set({
        videoEditSetting: {
          ...get().videoEditSetting,
          text: {
            ...get().videoEditSetting.text,
            font: 'boldify',
            color: '#FFFFFF',
            textStyles: {
              ...get().videoEditSetting.text.textStyles,
              customFont: {
                path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1680069452/Woxo/Idea2video/fonts/Montserrat-ExtraBold.ttf',
                fontFamily: 'Montserrat-ExtraBold'
              },
              stroke: '#000000',
              fill: '#FFFFFF',
              type: 'woxo-custom-text-basic'
            }
          }
        }
      });
    }

    let _sections = [...get().sections];
    Promise.allSettled(
      _sections.map((section, sectionIdx) => {
        return new Promise((resolve, reject) => {
          if (section.errors?.length === 0) {
            (async () => {
              _sections[sectionIdx].isLoading = true;
              set(
                {
                  sections: [..._sections],
                  videosBySections: getVideosBySections(_sections)
                },
                false,
                'Loading section'
              );

              // VOICE-OVER
              const _voiceOvers = await getVoiceOvers(
                section.voiceover,
                get().voiceOver,
                voices,
                preset.value
              );
              const fulfilled = validateFulfilled(_voiceOvers);
              if (fulfilled) {
                let voiceOvers = [];
                _voiceOvers.forEach((m) => {
                  voiceOvers.push(m.value);
                });

                let _currentSection = section;
                const _medias = section.medias;
                const _voiceoverAudio = { disabled: get().voiceOver.disabled, audios: voiceOvers };
                const _music = section.music;
                let _voiceover = section.voiceover;

                const _section = buildVideoSection(
                  _currentSection,
                  _voiceover,
                  _medias,
                  _voiceoverAudio,
                  _music,
                  get().videoProportion,
                  get().videoEditSetting.text.preset.value,
                  get().videoEditSetting.text.textStyles
                );

                _sections[sectionIdx] = _section;
                set(
                  {
                    sections: [..._sections],
                    videosBySections: getVideosBySections(_sections)
                  },
                  false,
                  'Update section'
                );

                // !- Update videos in history
                if (_section.videos[0]?.isCreated) {
                  await get().updateVideosHistoryAction(get().user._id, _section);
                }

                resolve(voiceOvers);
              } else {
                _sections[sectionIdx].errors.push({ error: 'An error' });
                _sections[sectionIdx].videos = updateVideosWithError(
                  [..._sections[sectionIdx].videos],
                  get().videoProportion
                );
                _sections[sectionIdx].isLoading = false;
                set(
                  {
                    sections: [..._sections],
                    videosBySections: getVideosBySections(_sections)
                  },
                  false,
                  'Error in voice over'
                );

                reject('An error');
              }
            })();
          } else {
            resolve();
          }
        });
      })
    ).then(() => {
      set({ isLoading: false, changeRunning: 'none' });
    });
  }
});
