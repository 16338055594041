import { v4 } from 'uuid';
import { data as songsList } from '../data/dataForMusic';
import { getVideosBySections, randomIntFromInterval } from '../helpers/helper';
import cloneDeep from 'lodash/cloneDeep';

export const useMusicStore = (set, get) => ({
  // state
  music: { label: 'By the AI', value: 'AI' },
  musicOptions: [
    { label: 'Turn Off', value: 'none' },
    { label: 'By the AI', value: 'AI' },
    { label: 'Christmas', value: 'christmas' },
    { label: 'Funny', value: 'funny' },
    { label: 'Epic', value: 'epic' },
    { label: 'Bright', value: 'bright' },
    { label: 'Happy', value: 'happy' },
    { label: 'Inspirational', value: 'inspirational' },
    { label: 'Romantic', value: 'romantic' },
    { label: 'Angry', value: 'angry' },
    { label: 'Calm', value: 'calm' },
    { label: 'Dark', value: 'dark' },
    { label: 'Dramatic', value: 'dramatic' },
    { label: 'Sad', value: 'sad' },
    { label: 'Hip Hop', value: 'hiphop' }
  ],

  // actions
  setMusicAction: (music) => {
    let _sections = [...get().sections];

    if (music.value === 'none') {
      _sections.map((section, sectionIdx) => {
        if (section.errors?.length === 0) {
          section.videos.map((video) => {
            video.id = v4();

            video.audioTracks.forEach((item, index, object) => {
              if (item.path.indexOf('res.cloudinary.com') !== -1) {
                object.splice(index, 1);
              }
            });
          });

          _sections[sectionIdx].id = v4();
          _sections[sectionIdx].isLoading = false;
          _sections[sectionIdx].music = [];
          set(
            { sections: [..._sections], videosBySections: getVideosBySections(_sections) },
            false,
            'Update section - music'
          );

          // !- Update videos in history
          if (_sections[sectionIdx].videos[0]?.isCreated) {
            get().updateVideosHistoryAction(get().user._id, _sections[sectionIdx]);
          }
        }
      });
    } else {
      _sections.map((section, sectionIdx) => {
        if (section.errors?.length === 0) {
          let _music = [];

          if (music.value === 'AI') {
            // TODO: improve this part
            _music = cloneDeep(
              songsList[get().musicOptions[randomIntFromInterval(2, 14)].value]
            ).sort(() => 0.5 - Math.random());
          } else _music = cloneDeep(songsList[music.value]).sort(() => 0.5 - Math.random());

          _music.length = _sections[sectionIdx].videos.length;

          section.videos.map((video, i) => {
            video.id = v4();

            const audio = { path: _music[i] };

            const containsVoiceover = video.audioTracks.some((v) => {
              if (v.path.indexOf('vod.front10.cloud') !== -1) {
                return true;
              }
              return false;
            });
            if (containsVoiceover) audio.mixVolume = 0.2;

            video.audioTracks.forEach((item, index, object) => {
              if (item.path.indexOf('res.cloudinary.com') !== -1) {
                object.splice(index, 1);
              }
            });
            video.audioTracks.push(audio);
          });

          _sections[sectionIdx].id = v4();
          _sections[sectionIdx].isLoading = false;
          _sections[sectionIdx].music = _music;
          set(
            { sections: [..._sections], videosBySections: getVideosBySections(_sections) },
            false,
            'Update section - music'
          );

          // !- Update videos in history
          if (_sections[sectionIdx].videos[0]?.isCreated) {
            get().updateVideosHistoryAction(get().user._id, _sections[sectionIdx]);
          }
        }
      });
    }

    set(
      {
        videoEditSetting: {
          ...get().videoEditSetting,
          music: music
        }
      },
      false,
      'Set music'
    );
  }
});
